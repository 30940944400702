import { ref, computed } from 'vue'

import {
  Paragraph,
  Heading,
  Text,
  Document,
  TextAlign,
  CustomImage,
  Image,
  DropCursor,
  Table,
  TableCell,
  TableRow,
  TableHeader,
  Bold,
  Italic,
  DragableNode,
  DonutChartNode,
  Iframe,
  BlockQuote,
  TwitterEmbedNode,
  History,
  Underline,
  CustomUnderline,
  Color,
  TextStyle,
  CustomLink,
  CustomTable,
  FontFamily,
  CustomFocus,
  HardBreak,
  CustomBubbleMenu,
  CustomImage2,
  BulletList,
  ListItem,
  OrderedList,
  FontSize
} from '@/services/TipTapExtensions'

export default function EditorConfig(bodyFont, headerFont) {
  const customHeading = ref(
    // Heading and Paragraph take fonts here because josh and jeff want it set once for all
    // if this changes and they want multiple fonts we will have to remove this and find a way to select
    // all headings or paragraphs individually
    Heading.extend({
      addAttributes() {
        return {
          ...this.parent?.(),
          fontFamily: {
            default: headerFont,
            renderHTML: (attrs) => {
              let fontFamily = attrs.fontFamily ? attrs.fontFamily : headerFont
              return {
                style: `font-family:${fontFamily}`
              }
            }
          }
        }
      }
    })
  )
  const customParagraph = ref(
    // Heading and Paragraph take fonts here because josh and jeff want it set once for all
    // if this changes and they want multiple fonts we will have to remove this and find a way to select
    // all headings or paragraphs individually
    Paragraph.extend({
      addAttributes() {
        return {
          ...this.parent?.(),

          fontFamily: {
            default: bodyFont,
            renderHTML: (attrs) => {
              let fontFamily = attrs.fontFamily ? attrs.fontFamily : bodyFont
              return {
                style: `font-family:${fontFamily}`
              }
            }
          }
        }
      }
    })
  )

  const textAlign = ref(
    TextAlign.configure({
      types: ['paragraph', 'heading', 'table', 'image', 'donutChart', 'iframe']
    })
  )

  const inlineImage = ref(CustomImage.configure({ inline: true }))
  const customColor = ref(Color.configure({ types: ['textStyle'] }))

  const customBulletList = ref(
    BulletList.configure({
      HTMLAttributes: {
        class: 'content'
      }
    })
  )

  const extensions = [
    Document,
    Text,
    customParagraph.value,
    customHeading.value,
    textAlign.value,
    //CustomImage,
    DropCursor,
    //Image,

    TableCell,
    TableRow,
    TableHeader,
    Bold,
    Italic,
    DragableNode,
    DonutChartNode,
    Iframe,
    BlockQuote,
    TwitterEmbedNode,
    History,
    inlineImage.value,
    Underline,
    CustomUnderline,
    TextStyle,
    customColor.value,
    CustomLink,
    CustomTable,
    FontFamily,
    CustomFocus,
    HardBreak,
    customBulletList.value,
    ListItem,
    OrderedList,
    FontSize
  ]

  return {
    extensions,
    textAlign,
    customHeading,
    customParagraph
  }
}
