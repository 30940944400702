import CoverSection, {
  generateInitialTextJson,
  generateInitialTitlePageContent,
  generateInitialContactPageContent,
  emptyDoc,
  generateTemplateBlockQuote,
  generateInitialLetterStoryContentJson,
  generateTemplateDonateContent
} from '@/services/coverSections/models'

export default CoverSection
export {
  generateInitialTextJson,
  generateInitialTitlePageContent,
  generateInitialContactPageContent,
  emptyDoc,
  generateTemplateBlockQuote,
  generateInitialLetterStoryContentJson,
  generateTemplateDonateContent
}
