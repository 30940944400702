import { onBeforeUpdate } from 'vue'

export function setElRefs(el, variable, index) {
  /**
   * el element to be referenced
   * variable containing array (becuase it is coming from template no need for .value)
   * index for key in array
   */
  if (el) variable[index] = el
}

export function clearRefs(variable) {
  // pass in variable .value
  variable = []
}

/**
 *
 * Usage
 *
 * <div v-for="(item,index) in items" :ref="(el)=>setElRefs(el,arrayForElRefs,index)"></div>
 *
 * function setUp(){
 *
 * const arrayForElRefs=ref([])
 *
 * onBeforeUpdate(()=>{
 *
 * //----USE ON BEFORE UPDATE TO CLEAR ARRAY-----
 * clearRefs(arrayForElRefs.value)
 * })
 *
 *
 * }
 *
 *
 *
 *
 *
 *
 */
