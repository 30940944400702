import { ref, computed, inject } from 'vue'
import { $vfm } from 'vue-final-modal'
import { useRouter } from 'vue-router'
import { v4 } from 'uuid'
import Report from '../services/reports/models'
import CoverSection, {
  emptyDoc,
  generateTemplateDonateContent,
  generateInitialLetterStoryContentJson
} from '@/services/coverSections/'

import yearlyStore from '@/composables/yearlyStore'

import { useModelWrapper } from '@/composables/modelValue'

import TableDataUpload from '@/components/TableDataUpload'

import ContributionLinkModal from '@/components/modals/ContributionLinkModal'

export default function reportFunctions() {
  const $alert = inject('$alert')
  const { user, setNewReportVersion } = yearlyStore()
  const savingReport = ref(false)
  const reportLoading = ref(true)
  const publishingReport = ref(false)
  const report = ref({})
  const scaleBy = ref(1)
  const reportContainer = ref(null)
  const router = useRouter()
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((ent) => {
      scaleBy.value = determineResizeScale(ent, 1400, 90, 1500)
    })
  })
  function routeToPreview() {
    let routeData = router.resolve({ name: 'PreviewPage', params: { rid: report.value.id } })

    window.open(routeData.href, '_blank')
  }
  const dynamicResizeObserver = (resizeBase, additionalOffset, max) =>
    new ResizeObserver((entries) => {
      entries.forEach((ent) => {
        scaleBy.value = determineResizeScale(ent, resizeBase, additionalOffset, max)
      })
    })

  function determineResizeScale(
    element,
    elementToResizeBase,
    additionalOffset = 0,
    maxResizeCutoff = 1400
  ) {
    const rect = element.contentRect
    let width = rect.width
    if (rect.width > maxResizeCutoff) {
      width = maxResizeCutoff
    }
    // if mobile or tablet scale down

    const rectWidth = width - additionalOffset

    //isTabletOrMobile ? rect.width : rect.width - 80
    return rectWidth / elementToResizeBase //COVER_SECTION_CONTAINER_WIDTH
  }
  async function updateReport(reportId, reportValue, firebaseId, rethrowError = false) {
    //setNewReportVersion(reportId, v4(), reportValue.toDict)
    try {
      savingReport.value = true
      $alert.alert({
        message: '<h2 class="subtitle">Saving Updates</h2>',
        type: 'info',
        timeout: 1000
      })
      await Report.api.update(reportId, reportValue, firebaseId)
      $alert.alert({
        message: '<h2 class="subtitle">Report Updated</h2>',
        type: 'success',
        timeout: 1000
      })
    } catch (e) {
      $alert.alert({
        message: '<h2 class="subtitle">Failed to Update</h2>',
        type: 'Error'
      })
      if (rethrowError) {
        throw e
      }
    } finally {
      savingReport.value = false
    }
  }
  async function deleteReport(id) {
    try {
      await Report.api.deleteReport(id)
      debugger
      $alert.alert({ message: 'Sucessfully deleted report', type: 'success' })
    } catch (e) {
      $alert.alert({ message: 'Failed to delete report please try again', type: 'error' })
      throw e
    }
  }
  async function duplicateReport(id) {
    try {
      const res = await Report.api.duplicateReport(id)
      return res
    } catch (e) {
      $alert.alert({ message: 'Failed to duplciate report please try again', type: 'error' })
      throw e
    }
  }
  async function retrieveReport(id, filters = {}) {
    try {
      reportLoading.value = true
      const res = await Report.api.retrieve(id, { ...filters })
      report.value = res
    } catch (e) {
      console.log(e)
      alert('There was en error grabbing report' + e)
    } finally {
      reportLoading.value = false
    }
  }
  async function publishReport(reportSlug, firebaseId) {
    try {
      publishingReport.value = true
      await Report.api.publishReport(report.value.id, firebaseId, reportSlug)
    } finally {
      publishingReport.value = false
    }
  }
  const showFooterBuilder = computed(() => {
    return !user.value.isPlanPaid || (user?.value.isPlanPaid && report.value?.showFooter)
  })
  const sections = computed({
    get: () => {
      return report.value?.sections || []
    },
    set: (val) => (report.value.sections = val)
  })
  function routeToEditReport(rid) {
    router.push({ name: 'BuilderPage', query: { rid } })
  }
  return {
    updateReport,
    savingReport,
    deleteReport,
    reportLoading,
    retrieveReport,
    report,
    publishingReport,
    publishReport,
    showFooterBuilder,
    sections,
    scaleBy,
    reportContainer,
    resizeObserver,
    routeToPreview,
    routeToEditReport,
    duplicateReport,
    dynamicResizeObserver
  }
}

export function reportSectionFunctions(props, emit, report = ref({})) {
  const { user } = yearlyStore()

  const bgImage = useModelWrapper(props, emit, 'sectionBgImage')
  const bgImageSize = useModelWrapper(props, emit, 'sectionBgImageSize')
  const generatedJsonValue = useModelWrapper(props, emit, 'generatedJson')
  const sectionHeaderModelValue = useModelWrapper(props, emit, 'sectionHeader')
  const includeInWebValue = useModelWrapper(props, emit, 'includeInWeb')
  const includeInPrintValue = useModelWrapper(props, emit, 'includeInPrint')

  const sections = computed({
    get: () => {
      return report.value?.sections || []
    },
    set: (val) => (report.value.sections = val)
  })

  function removeBgImage() {
    bgImage.value = null
  }

  function onAddEqSplitSection(index, section = {}, count = 2) {
    const currentContainerOrder = report.value.sections[index].sections.length
    let container = { ...section, sectionHeight: 400 }
    let childSectionTemplate = {
      ...section,
      sectionHeight: 400,
      sectionBgImageConfig: { size: 'cover' }
    }

    container = CoverSection.create({
      ...container,
      generatedJson: {},
      order: currentContainerOrder + 1,
      isSectionContainer: true
    })

    let sectionFlex = Math.floor(12 / count)
    let childSections = []
    for (let i = 0; i < count; i++) {
      let childSection = CoverSection.create({
        ...childSectionTemplate,
        sectionFlex: sectionFlex,
        order: i + 1
      })
      childSections = [...childSections, childSection]
    }

    container['sections'] = [...childSections]

    sections.value[index].sections = [...sections.value[index].sections, container]
  }
  function setEmptySectionHeader(sectionIndex) {
    if (
      !sections.value[sectionIndex].sectionHeader ||
      !sections.value[sectionIndex].sectionHeader.length
    ) {
      sections.value[sectionIndex].sectionHeader = 'Untitled Chapter'
    }
  }
  function toggleTableModal() {
    $vfm.show({
      component: TableDataUpload,
      on: {
        'table-data-ready': (e) => {
          emit('addTableElement', e)
          $vfm.hideAll()
        }
      }
    })
  }

  function updateAndEmitSelectedColor(val) {
    if (val) {
      const { r, g, b, a } = { ...val.rgba }
      emit('update:sectionBgColor', `rgba(${r},${g},${b},${a})`)
    }
  }
  function updateChildSectionHeight(type, sectionOrder) {
    emit('update-child-section-height', { type, sectionOrder })
  }
  function duplicateModuleContainer(index) {
    emit('duplicate-module-container', index)
  }
  function duplicateChapterContainer(index) {
    emit('duplicate-chapter-container', index)
  }

  function deleteSectionContainer(sectionOrder) {
    emit('delete-section-container', sectionOrder)
  }
  function deleteCoverSection() {
    emit('delete-cover-section')
  }
  function updateChildSectionOrder(direction, currentOrder) {
    emit('update-cs-order', { currentOrder, direction })
  }
  function updateChildSectionContainerOrder(direction, currentOrder) {
    emit('update-cs-cont-order', { currentOrder, direction })
  }
  function addContributionModule(index, link) {
    onAddEqSplitSection(
      index,
      {
        generatedJson: generateTemplateDonateContent(link),
        sectionBgColor: 'rgba(116, 116, 116, 1)'
      },
      1
    )
  }

  function changeToOptimizedImageUrl(imgUrl) {
    let newUrl = ''
    if (imgUrl) {
      newUrl = imgUrl.replace(
        process.env.VUE_APP_FIREBASE_STORE_URI,
        process.env.VUE_APP_IMAGE_KIT_URL
      )
    }

    return newUrl
  }
  function onAddLetterStory(index) {
    const currentContainerOrder = report.value.sections[index].sections.length

    let container = CoverSection.create({
      generatedJson: {},
      order: currentContainerOrder + 1,
      isSectionContainer: true,
      sectionHeight: 800
    })

    let childSections = []

    let childSection1 = CoverSection.create({
      sectionFlex: 6,
      generatedJson: generateInitialLetterStoryContentJson(),
      order: 1,
      sectionHeight: 800
    })
    let childSection2 = CoverSection.create({
      sectionFlex: 6,
      order: 2,
      generatedJson: emptyDoc,
      sectionBgImage:
        'https://images.unsplash.com/photo-1549924231-f129b911e442?crop=entropy&cs=srgb&fm=jpg&ixid=Mnw4MDYyM3wwfDF8c2VhcmNofDl8fGxvZ298ZW58MHx8fHwxNjM2NDk0NTMy&ixlib=rb-1.2.1&q=85',
      sectionHeight: 800
    })
    childSections = [childSection1, childSection2]
    container['sections'] = childSections
    report.value.sections[index].sections = [...report.value.sections[index].sections, container]
  }

  return {
    user,
    bgImage,
    generatedJsonValue,
    sectionHeaderModelValue,
    updateChildSectionOrder,
    updateChildSectionContainerOrder,
    removeBgImage,
    toggleTableModal,
    updateAndEmitSelectedColor,
    updateChildSectionHeight,
    deleteSectionContainer,
    deleteCoverSection,
    bgImageSize,
    addContributionModule,
    onAddEqSplitSection,
    onAddLetterStory,
    setEmptySectionHeader,
    changeToOptimizedImageUrl,
    includeInPrintValue,
    includeInWebValue,
    duplicateModuleContainer,
    duplicateChapterContainer
  }
}
