import cloneDeep from 'lodash.clonedeep'

import Model, { fields } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import { ReportAPI } from './api'
import CoverSection from '../coverSections'

class ReportToc extends Model {
  static id = new fields.IdField({ readOnly: true })
  static includeInPrint = new fields.Field({ readOnly: true, defaultVal: false })
  static includeInWeb = new fields.Field({ readOnly: true, defaultVale: false })
  static order = new fields.IntegerField({ readOnly: true })
  static sectionHeader = new fields.CharField({ readOnly: true })
  static fromAPI(json = {}) {
    return new this(objectToCamelCase(json))
  }
  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
      if (
        data[key] instanceof Model ||
        (Array.isArray(data[key]) && data[key].length && data[key][0] instanceof Model)
      ) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].map((value) => {
            return value.toDict
          })
        } else data[key] = data[key].toDict
      }
    })
    return data
  }
}

export default class Report extends Model {
  static api = ReportAPI.create(Report)

  static id = new fields.IdField({ readOnly: true })
  static reportTitle = new fields.CharField({})
  static subscriptionStatus = new fields.CharField({ readOnly: true })
  static builderLink = new fields.CharField({ readOnly: true })
  static firebaseId = new fields.CharField({ readOnly: true })
  static email = new fields.CharField({ readOnly: true })
  static seoEnabled = new fields.BooleanField({ defaultVal: false, readOnly: true })
  static getBuildingReportToc = new fields.ModelField({
    ModelClass: ReportToc,
    defaultVal: [],
    readOnly: true,
    many: true
  })
  static reportToc = new fields.ModelField({
    ModelClass: ReportToc,
    defaultVal: [],
    readOnly: true,
    many: true
  })
  static createdAt = new fields.CharField({ readOnly: true })
  static reportStatus = new fields.CharField({ readOnly: true })

  static name = new fields.CharField({ readOnly: true })
  static organization = new fields.CharField({ readOnly: true })
  static newBuilderLink = new fields.CharField({ readOnly: true })
  static premium = new fields.BooleanField({ readOnly: true })
  static publishedAt = new fields.CharField({ readOnly: true })

  static publishedReportSections = new fields.ModelField({
    ModelClass: CoverSection,
    defaultVal: [],
    many: true,
    readOnly: true
  })
  static publishedReportToc = new fields.ModelField({
    ModelClass: ReportToc,
    defaultVal: [],
    readOnly: true,
    many: true
  })
  static reportBuilderVersion = new fields.IntegerField({ defaultVal: 2 })
  static reportContent = new fields.Field({ readOnly: true })
  static reportCover = new fields.Field()
  static reportMarkup = new fields.Field({ readOnly: true })
  static reportSlug = new fields.Field({ readOnly: true })
  static reportViews = new fields.IntegerField({ readOnly: true })
  static reportYear = new fields.IntegerField()

  static showFooter = new fields.Field({ defaultVal: true })
  static subscriptionStatus = new fields.Field({ readOnly: true })

  static bodyFont = new fields.CharField()
  static headingFont = new fields.CharField()
  static sections = new fields.ModelField({
    ModelClass: CoverSection,
    defaultVal: [],
    many: true
  })
  static headingFontUrl = new fields.Field({ readOnly: false })
  static bodyFontUrl = new fields.Field({ readOnly: false })
  static headingFontIsCustom = new fields.Field({ readOnly: false })
  static bodyFontIsCustom = new fields.Field({ readOnly: false })
  static username = new fields.Field({ readOnly: true })
  static subscribedPlan = new fields.Field({ readOnly: true })

  static clone() {
    return cloneDeep(this)
  }
  static toAPI(obj, fields = [], excludeFields = []) {
    // By default, send the whole object
    let data = {}

    // If it's a partial update, get only the fields specified
    if (fields.length > 0) {
      fields.forEach((field) => {
        data[field] = obj[field]
      })
    } else {
      data = { ...obj }
    }

    // Delete private '_fields' member
    delete data['_fields']
    // format it the way it is expected

    // Remove read only and excluded fields
    ;[...this.getReadOnlyFields(), ...excludeFields].forEach((item) => {
      delete data[item]
    })
    // check if it is a model class
    Object.keys(data).forEach((k) => {
      if (
        data[k] instanceof Model ||
        (Array.isArray(data[k]) && data[k].length && data[k][0] instanceof Model)
      ) {
        if (Array.isArray(data[k])) {
          data[k] = data[k].map((value) => {
            return value.constructor.toAPI(value)
          })
        } else data[k] = data[k].constructor.toAPI(data[k])
      }
    })

    return objectToSnakeCase(data)
  }

  static fromAPI(json = {}) {
    let publishedReportToc = json['published_report_toc']
    if (
      !publishedReportToc ||
      !Array.isArray(publishedReportToc) ||
      !Object.keys(publishedReportToc).length
    ) {
      json['published_report_toc'] = []
    }

    return new this(objectToCamelCase(json))
  }
  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
      if (
        data[key] instanceof Model ||
        (Array.isArray(data[key]) && data[key].length && data[key][0] instanceof Model)
      ) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].map((value) => {
            return value.toDict
          })
        } else data[key] = data[key].toDict
      }
    })
    return data
  }
}
